<template>
  <table class="table table-hover table-striped">
    <thead>
      <tr>
        <!-- <th class="header" scope="col">#</th> -->
        <th class="header" scope="col col-2-">Nombre de Farmacia</th>
        <th class="header" scope="col col-1">Validated</th>
        <th class="header" scope="col-1">Status</th>
        <th class="header" scope="col-1">Shopper</th>
        <th class="header" scope="col col-7">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(store, index) in allStore" :key="index">
        <!-- <th class="header" scope="row">{{increment()}}</th> -->
        <td class="content">
          <p style="font-size: 16px; text-align: center;">
            {{ store.get("Name") }}
          </p>
          <p style="text-align: center;">
            {{ store.get("employee").get("username") }}
          </p>
          <p style="text-align: center;">
            {{ store.get("employee").get("fullName") }}
          </p>
        </td>
        <td class="content">
          <img
            v-if="store.get('employee').get('userValidated')"
            src="../assets/icons/validated.png"
            style="width:20px; height:25px"
          />
        </td>
        <td class="content">
          <img
            v-if="store.get('activate')"
            src="../assets/icons/validated.png"
            style="width:20px; height:25px"
          />
        </td>
        <td class="content">
          <button
            v-bind:class="{
              'btn btn-danger': store.get('esShopper'),
              'btn btn-secondary': !store.get('esShopper')
            }"
            @click="
              store.get('esShopper') ? onShopperOff(store) : onShopperOn(store)
            "
          >
            {{ store.get("esShopper") ? "Shopper" : "No Shopper" }}
          </button>
        </td>
        <td class="content">
          <button
            @click="viewInfo(store.id)"
            type="button"
            class="see-btn"
            style="background:#35bead;"
          >
            Ver
          </button>
          <button
            type="button"
            class="see-btn2"
            data-toggle="modal"
            data-target="#exampleModalCenter"
            @click="openPorcentage(store)"
            style="font-family: 'Montserrat', sans-serif;"
          >
            Porcentaje
          </button>
          <button
            type="button"
            class="see-btn2"
            @click="getInventoryFromMCS(store)"
            style="font-family: 'Montserrat', sans-serif;"
          >
            inventario desde MCS
          </button>
          <router-link :to="`/FarmacyInventory/${store.id}`">
            <button
              type="button"
              style="font-family: 'Montserrat', sans-serif;"
              class="see-btn2"
            >
              Ver Inventario
            </button>
          </router-link>
          <button
            @click="store.get('activate') ? turnOff(store) : turnOn(store)"
            type="button"
            v-bind:class="{
              Active: store.get('activate'),
              inactive: !store.get('activate')
            }"
          >
            {{ store.get("activate") ? "Activa" : "Inactiva" }}
          </button>
          <!--  delete -->
          <button @click="deleteStore(store)" type="button" class="see-btn">
            Borrar
          </button>

          <button
            data-toggle="modal"
            data-target="#exampleModalCenter2"
            @click="setPrioridad(store)"
            type="button"
            class="see-btn"
            style="background:#E32C2D;"
          >
            Prioridad
          </button>
          <button
            @click="proccessEmailRestoring(store)"
            type="button"
            class="see-btn"
            style="background:#fd7e7f;"
          >
            Password
          </button>
          <!-- end delete -->
        </td>
      </tr>
      <!-- modal porcentaje -->
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style="font-family: 'Montserrat', sans-serif; height: 800px;"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Porcentaje
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group">
                  <label for="porcentaje">Porcentaje</label>
                  <input
                    type="number"
                    class="form-control"
                    id="porcentaje"
                    placeholder="10%"
                    v-model="percent"
                  />
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                id="close-btn"
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                style="font-family: 'Montserrat', sans-serif; font-size: 13px; height: 34px; background: #FFC93A; border: 0;"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                style="font-family: 'Montserrat', sans-serif; font-size: 13px; height: 34px; background: #0e9390; border: 0;"
                @click="savePercent()"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- modal porcentaje -->
      <!-- modal prioridad -->
      <div
        class="modal fade"
        id="exampleModalCenter2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style="font-family: 'Montserrat', sans-serif; height: 800px;"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Orden de Prioridad
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group">
                  <label for="prioridad">Prioridad</label>
                  <input
                    type="number"
                    class="form-control"
                    id="prioridad"
                    placeholder="Entra un valor"
                    v-model="prioridad"
                  />
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                id="close-btn"
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                style="font-family: 'Montserrat', sans-serif; font-size: 13px; height: 34px; background: #FFC93A; border: 0;"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                style="font-family: 'Montserrat', sans-serif; font-size: 13px; height: 34px; background: #0e9390; border: 0;"
                @click="savePrioridad()"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- modal prioridad-->
      <div v-if="backdrop" class="loader">
        <div :class="loader ? 'loader-show' : ''" class="loader-container ">
          <RotateSquare2 />
          <p class="loader-text">Loading...</p>
        </div>
      </div>
    </tbody>
  </table>
</template>
<script>
import { RotateSquare2 } from "vue-loading-spinner";
import Parse from "parse";
import XLSX from "xlsx";
import axios from "axios";
export default {
  name: "FarmacyActiveList",
  components: {
    RotateSquare2
  },
  props: ["filter"],
  data() {
    return {
      documentosCorreo: [],
      emailBody: "",
      esEmailBody: false,
      emailTitle: "",
      esEmailTitle: false,
      categoryList: [],
      validatedTotal: 0,
      totalStripe: 0,
      storeTotal: 0,
      categories: [],
      Name: "",
      esName: false,
      Last: "",
      esLast: false,
      Phone: "",
      esPhone: false,
      Email: "",
      esEmail: false,
      Farmacy: "",
      esFarmacy: false,
      Address: "",
      esAdress: false,
      Descripcion: "",
      dataInfo: [],
      dataProductInfo: [],
      dataProducts: [],
      superAdmin: false,
      admin: false,
      allStore: [],
      counter: undefined,
      newvalue: undefined,
      finish: undefined,
      store: 0,
      percent: undefined,
      processing: undefined,
      prioridad: undefined,
      superadmin: false,
      filtroFarmacia: "",
      nuevaCategoria: "",
      loader: false,
      backdrop: false,
      farmaciasCreadas: [],
      usuariosCreados: [],
      manejoCreados: [],
      esFarmacia: true,
      files: [],
      picture: null,
      pictureUrl: null,
      esGuardar: false,
      totalDataInfo: 0,
      totalDataProductInfo: 0,
      totalDataProduct: 0,
      seleccionados: [],
      storeSelected: "",
      storeSelectedId: "",
      storeSel: null,
      todoselect: true,
      totalSeleccion: 0,
      carga: 0,
      productos: 0,
      uploading: false,
      processingUpload: false,
      processingImages: false,
      allProducts: [],
      imageFiles: [], //all files selected from input / computer
      productImages: [], //parse file of images to update products
      productImageCount: 0
    };
  },
  mounted() {
    this.gettingStores();
  },
  watch: {
    filter() {
      this.allStore = [];
      if (isNaN(this.filter) && this.filter !== "") {
        this.getStoresByName();
      } else if (!isNaN(this.filter) && this.filter !== "") {
        this.getStoresByNPI();
      } else {
        this.gettingStores();
      }
      // this.seleccionados = [];
    },
    seleccionados() {
      this.totalSeleccion = this.seleccionados.length;
    },
    dataInfo() {
      this.totalDataInfo = this.dataInfo.length;
    },
    dataProductInfo() {
      this.totalDataProductInfo = this.dataProductInfo.length;
    },
    dataProducts() {
      this.totalDataProduct = this.dataProducts.length;
    },
    files() {
      this.loader = true;
      this.backdrop = true;
      setInterval(() => {
        this.loader = false;
        this.backdrop = false;
      }, this.files.length * 3000);
    }
  },
  methods: {
    verseleccion(key, id) {
      let ans = false;
      try {
        if (this.seleccionados[key].id === id) {
          ans = true;
        }
      } catch (ex) {
        ans = false;
      }
      console.log(ans);
      return ans;
    },
    agregar() {
      this.documentosCorreo.push({
        nombre: "",
        enlace: ""
      });
    },
    quitar(archivo) {
      this.documentosCorreo.splice(this.documentosCorreo.indexOf(archivo), 1);
    },
    keydown(e) {
      if (e.code === "Enter") {
        this.emailBody += "<br>";
      }
    },
    selectTodo() {
      if (this.todoselect) {
        this.todoselect = false;
        this.seleccionados = this.allStore;
      } else {
        this.todoselect = true;
        this.seleccionados = [];
      }
    },
    sendNotification() {
      if (this.emailTitle === "") {
        this.esEmailTitle = true;
      } else {
        this.esEmailTitle = false;
      }
      if (this.emailBody === "") {
        this.esEmailBody = true;
      } else {
        this.esEmailBody = false;
      }
      if (!this.esEmailBody && !this.esEmailTitle) {
        for (let store of this.seleccionados) {
          this.backdrop = true;
          this.loader = true;
          this.sendEmailNotificacion({
            Correo: store.attributes.employee.attributes.username,
            Title: this.emailTitle,
            Body: this.emailBody,
            Nombre: store.attributes.Name,
            archivos: this.documentosCorreo
          });
          this.backdrop = false;
          this.loader = false;
        }
        alert("Envio de notificaciones realizadas con exito");
      }
    },
    sendEmailNotificacion(cuerpoCorreo) {
      let archivos = "";
      if (cuerpoCorreo.archivos.length > 0) {
        archivos += "<div style='width:800px'>";
        for (let file of cuerpoCorreo.archivos) {
          archivos +=
            "<div style='width:100px; float: left; margin-right: 50px; text-align: center'>" +
            "<a href='" +
            file.enlace +
            "'>" +
            "<img width='50'  src='https://image.flaticon.com/icons/png/512/13/13566.png'>" +
            "</a>" +
            "<p style='margin-top:-2px; text-align: center;'>" +
            file.nombre +
            "</p>" +
            "</div>";
        }
        archivos += "</div>";
      }
      // eslint-disable-next-line
      Email.send({
        Host: "smtp.gmail.com",
        Username: "serviciotecnico@coopharma.coop",
        Password: "Mysh0p2020",
        To: cuerpoCorreo.Correo,
        From: "serviciotecnico@coopharma.coop",
        Subject: cuerpoCorreo.Title,
        Body:
          "<div style='background-color:#E0E0E0; padding: 20px; width: 80%;'>" +
          "<a href='www.coopharma.coop'><img width='70' src='https://coopharma.app/img/logo-coopharma.e5f23e05.png'></a>" +
          "</div>" +
          "<div style='margin:40px; width:70%; text-align: justify;'>" +
          "<p style = 'font-size: 20px;'>Hola " +
          cuerpoCorreo.Nombre +
          ",</p>" +
          "<p style = 'font-size: 20px;'>" +
          cuerpoCorreo.Body +
          ",</p>" +
          archivos +
          "<p style = 'font-size: 20px; float: left;'>Para cualquier duda o inconveniente puede llamar a (787) 520-6018,<br><br>Saludos</p><div><a href='www.coopharma.app'><button style='width:100%;'><p>" +
          "<img width='70' src='https://coopharma.app/img/logo-coopharma.e5f23e05.png'>" +
          "<p style='font-size: 20px; font-weight:bold; color: #ED7422;'>Ir a la Plataforma</p></button></a></div></div>"
      }).then(() => {
        this.seleccionados = [];
        this.documentosCorreo = [];
        this.emailBody = "";
        this.emailTitle = "";
      });
    },
    getStoresByNPI() {
      let query = new Parse.Query("Store");
      query.include("categoryId");
      query.include("employee");
      query.include("logkey");
      query.include("users");
      query.equalTo("status", "V");
      query.equalTo("NPI", this.filter);
      query.find().then(result => {
        this.allStore = [];
        for (let data of result) {
          this.allStore.push(data);
        }
      });
    },
    getStoresByName() {
      let query = new Parse.Query("Store");
      query.include("categoryId");
      query.include("employee");
      query.include("logkey");
      query.include("users");
      query.equalTo("status", "V");
      query.contains("Name", this.filter, { diacriticSensitive: true });
      // query.matches("Name", this.filtroFarmacia, {diacriticSensitive: false });
      // query.fullText('Name', this.filtroFarmacia, {diacriticSensitive: false });
      query.find().then(result => {
        this.allStore = [];
        for (let data of result) {
          this.allStore.push(data);
        }
      });
    },
    sendEmailRestoreOperario(cuerpoCorreo) {
      // eslint-disable-next-line
      Email.send({
        Host: "smtp.gmail.com",
        Username: "serviciotecnico@coopharma.coop",
        Password: "Mysh0p2020",
        To: cuerpoCorreo.Correo,
        From: "serviciotecnico@coopharma.coop",
        Subject: "Restablecer Password",
        Body:
          "<div style='background-color:#E0E0E0; padding: 20px; width: 80%;'>" +
          "<a href='www.coopharma.coop'><img width='70' src='https://coopharma.app/img/logo-coopharma.e5f23e05.png'></a>" +
          "</div>" +
          "<div style='margin:40px; width:70%; text-align: justify;'>" +
          "<p style = 'font-size: 20px;'>Hola " +
          cuerpoCorreo.Nombre +
          ",</p>" +
          "<p style = 'font-size: 20px;'>Este es un correo de reestablecimiento de usuario en la plataforma de " +
          "<a href='www.coopharma.app' style='color:#0E9390; font-weight:bold;'>www.coopharma.app</a>" +
          ", Para poder validar sus datos en la plataforma con las siguientes credenciales:</p>" +
          "<p style = 'font-size: 20px;'><b style='color:#0E9390;'>Usuario:</b> " +
          cuerpoCorreo.Correo +
          "<br>" +
          "<b style='color:#0E9390;'>Contraseña:</b> " +
          cuerpoCorreo.Password +
          "</p>" +
          "<p>Para poder ingresar acceda a la plataforma usando este enlace <a href='www.coopharma.app'>www.coopharma.app</a>,</p>" +
          "<p style = 'font-size: 20px;'>Una vez haya validado su usuario proceda en la opcion de perfil a cambiar su " +
          "contrasenia,<br>Saludos</p><div><a href='www.coopharma.app'><button style='width:100%;'><p>" +
          "<img width='70' src='https://coopharma.app/img/logo-coopharma.e5f23e05.png'>" +
          "<p style='font-size: 20px; font-weight:bold; color: #ED7422;'>Ir a la Plataforma</p></button></a></div></div>"
      });
    },
    sendEmailRestoreUser(cuerpoCorreo) {
      // eslint-disable-next-line
      Email.send({
        Host: "smtp.gmail.com",
        Username: "serviciotecnico@coopharma.coop",
        Password: "Mysh0p2020",
        To: cuerpoCorreo.Correo,
        From: "serviciotecnico@coopharma.coop",
        Subject: "Restablecer Password",
        Body:
          "<div style='background-color:#E0E0E0; padding: 20px; width: 80%;'>" +
          "<a href='www.coopharma.coop'><img width='70' src='https://coopharma.app/img/logo-coopharma.e5f23e05.png'></a>" +
          "</div>" +
          "<div style='margin:40px; width:70%; text-align: justify;'>" +
          "<p style = 'font-size: 20px;'>Hola " +
          cuerpoCorreo.Nombre +
          ",</p>" +
          "<p style = 'font-size: 20px;'>Este es un correo de reestablecimiento de usuario en la plataforma de <a href='www.coopharma.com'>www.coopharma.com</a>" +
          "<a href='www.coopharma.app' style='color:#0E9390; font-weight:bold;'>www.coopharma.app</a>" +
          ", Para poder proceder acceda via el boton a continuacion en este correo:</p>" +
          "<div><a href='www.coopharma.app/?#/passwordRestore/" +
          cuerpoCorreo.FarmaciaID +
          "'><button style='width:100%;'><p>" +
          "<img width='70' src='https://coopharma.app/img/logo-coopharma.e5f23e05.png'>" +
          "<p style='font-size: 20px; font-weight:bold; color: #ED7422;'>Cambiar Password de Plataforma</p></button></a></div></div>" +
          "<br><br><p>Para cualquier duda o inconveniente puede llamar a (787) 520-6018</p>"
      });
    },
    proccessEmailRestoring(data) {
      this.sendEmailRestoreUser({
        FarmaciaID: data.id,
        Nombre: data.get("employee").get("fullName"),
        Correo: data.get("employee").get("username")
      });
      alert("Correo de restauracion ha sido enviado a " + data.get("Name"));
      // this.$swal({
      //   title: "Email Enviado",
      //   text: "Correo de restauracion ha sido enviado a "+data.get("Name"),
      //   type: "success",
      // });
    },
    proccessFarmaciaFile(event) {
      let selectedFile;
      selectedFile = event.target.files[0];
      let data = [
        {
          name: "jayanth",
          data: "scd",
          abc: "sdef"
        }
      ];
      XLSX.utils.json_to_sheet(data, "out.xlsx");
      if (selectedFile) {
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(selectedFile);
        fileReader.onload = event => {
          let data = event.target.result;
          let workbook = XLSX.read(data, { type: "binary" });
          workbook.SheetNames.forEach(sheet => {
            let rowObject = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            this.dataInfo = rowObject;
          });
        };
      }
    },
    proccessPicturesFile(event) {
      this.files = event.target.files;
      for (let ruta of this.dataProductInfo) {
        let fileName = ruta.PATH;
        let reverse = fileName
          .split("")
          .reverse()
          .join("");
        let tempFileName = reverse.slice(0, reverse.indexOf("\\"));
        fileName = tempFileName
          .split("")
          .reverse()
          .join("");
        for (let file of this.files) {
          if (file.name === fileName) {
            this.onFileSelected(file, this.dataProductInfo.indexOf(ruta));
          }
        }
      }
    },
    async proccessCategoryFarmacies() {
      this.backdrop = true;
      this.loader = true;
      if (this.allStore) {
        let category = this.nuevaCategoria.trim().toUpperCase();
        for (let store of this.seleccionados) {
          let validate = await this.validateCategory(category, store);
          console.log(validate);
          if (validate) {
            let SubCategory = Parse.Object.extend("SubCategory");
            let subCategory = new SubCategory();
            subCategory.set("employeeId", store.attributes.employee);
            subCategory.set("storeId", store);
            subCategory.set("subCategory", category);
            subCategory.save();
          }
          // let objectsId = this.seleccionados.map(x => x.id);
          // let itemsCount = 100;
          // for (let i = 0; i < objectsId.length; i = i + itemsCount) {
          //   let productsList = [],
          //     end =
          //       i + itemsCount <= objectsId.length ? i + itemsCount : objectsId.length;
          //   for (let n = i; n < end; n++) {
          //     productsList.push(objectsId[n]);
          //   }
          //   let result=await Parse.Cloud.run("uploadCategories", {
          //     seleccionados: productsList,
          //     category: category
          //   });
          //   console.log(result);
          // }
        }
      }
      this.backdrop = false;
      this.loader = false;
      alert("Categorias Precargadas con Exito");
      // this.seleccionados = [];
    },
    validateCategory(category, store) {
      return new Promise((resolve, reject) => {
        let query = new Parse.Query("SubCategory");
        query.equalTo("subCategory", category);
        query.equalTo("storeId", store);
        query
          .count()
          .then(result => {
            if (result === 0) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    onFileSelect() {
      let self = this;
      const toBase64 = file =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      async function Main() {
        const file = document.querySelector("#documentoEmail").files[0];
        self.uploading = true;
        self.picture = await toBase64(file);
        const base64Image = self.picture;
        const name = "archivo";
        const parseFile = new Parse.File(name, {
          base64: base64Image
        });
        parseFile.save().then(
          savedFile => {
            localStorage.setItem("archivoEmail", JSON.stringify(savedFile));
            self.picture = savedFile;
            self.uploading = false;
          },
          err => {
            self.uploading = false;
            alert(err);
          }
        );
      }
      Main();
    },
    proccessMultipleProductFarmacies() {
      this.backdrop = true;
      this.loader = true;

      let totalProducts = this.dataProductInfo.length;
      let totalFotos = 0;
      for (let a = 0; a <= totalProducts; a++) {
        if (localStorage.getItem(a.toString())) {
          totalFotos++;
        }
      }
      if (
        confirm(
          "Tiene " +
            totalProducts +
            " productos y " +
            totalFotos +
            " fotos a subir a " +
            this.totalSeleccion +
            " farmacias, deseas proceder la subida de productos con estas fotos?"
        )
      ) {
        if (this.seleccionados) {
          for (let store of this.seleccionados) {
            if (this.dataProductInfo) {
              for (let dataproduct of this.dataProductInfo) {
                if (
                  localStorage.getItem(
                    this.dataProductInfo.indexOf(dataproduct)
                  )
                ) {
                  let archivoData = JSON.parse(
                    localStorage.getItem(
                      this.dataProductInfo.indexOf(dataproduct)
                    )
                  );
                  const Product = Parse.Object.extend("Products");
                  const product = new Product();
                  product.set("Price", "0");
                  product.set("Description", "Producto precargado de Farmacia");
                  product.set("Name", dataproduct.ProductName);
                  product.set("employee", store.get("employee").toPointer());
                  product.set("storeId", store);
                  product.set("picture", archivoData);
                  product.set("SubCategory", "OTC");
                  product.set("outOfStock", true);
                  product.set("OriginalPrice", "0");
                  product.set("applyIvu", false);
                  product.set("ivuPercent", 0);
                  product.set("quantity", "0");
                  product.set("shopper", 0);
                  product.set("esShopper", false);
                  product.set("offer", 0);
                  product.set("IsOffer", false);
                  product.set("dist", dataproduct.Dist);
                  product.set("upc11", dataproduct["11DigitUPC"]);
                  product.set("CIN_GCN", parseInt(dataproduct.CIN_GCN));
                  product.set("majorUnit", dataproduct.MajorUnit);
                  product.set("minorUnit", dataproduct.MinorUnit);
                  product.set("ndc", parseInt(dataproduct.NDC));
                  product.set("packSize", dataproduct.PackSize);
                  product.set(
                    "productNumberFamily",
                    dataproduct.ProductNumberFamily
                  );
                  product.set("supplierName", dataproduct.SupplierName);
                  product.set("upc", dataproduct.UPC);
                  product.set("barcode", dataproduct.UPC);
                  product.save().catch(error => {
                    this.$swal({
                      title: "Ha ocurrido un error",
                      icon: "error",
                      html: error
                    });
                  });
                } else {
                  const Product = Parse.Object.extend("Products");
                  const product = new Product();
                  product.set("Price", "0");
                  product.set("Description", "Producto precargado de Farmacia");
                  product.set("Name", dataproduct.ProductName);
                  product.set("employee", store.get("employee").toPointer());
                  product.set("storeId", store);
                  product.set("SubCategory", "OTC");
                  product.set("outOfStock", true);
                  product.set("OriginalPrice", "0");
                  product.set("applyIvu", false);
                  product.set("ivuPercent", 0);
                  product.set("quantity", "0");
                  product.set("shopper", 0);
                  product.set("esShopper", false);
                  product.set("offer", 0);
                  product.set("IsOffer", false);
                  product.set("dist", dataproduct.Dist);
                  product.set("upc11", parseInt(dataproduct["11DigitUPC"]));
                  product.set("CIN_GCN", parseInt(dataproduct.CIN_GCN));
                  product.set("majorUnit", dataproduct.MajorUnit);
                  product.set("minorUnit", dataproduct.MinorUnit);
                  product.set("ndc", parseInt(dataproduct.NDC));
                  product.set("packSize", dataproduct.PackSize);
                  product.set(
                    "productNumberFamily",
                    dataproduct.ProductNumberFamily
                  );
                  product.set("supplierName", dataproduct.SupplierName);
                  product.set("upc", dataproduct.UPC);
                  product.set("barcode", dataproduct.UPC);
                  product.save().catch(error => {
                    this.$swal({
                      title: "Ha ocurrido un error",
                      icon: "error",
                      html: error
                    });
                  });
                }
              }
            }
          }
          this.backdrop = false;
          this.loader = false;
          alert("Productos Precargados con Exito");
          this.seleccionados = [];
        }
        this.loader = false;
        this.backdrop = false;
      } else {
        this.loader = false;
        this.backdrop = false;
      }
    },
    proccessFilePictures(event) {
      this.files = event.target.files;
      for (let ruta of this.dataProducts) {
        let fileName = ruta.PATH;
        let reverse = fileName
          .split("")
          .reverse()
          .join("");
        let tempFileName = reverse.slice(0, reverse.indexOf("\\"));
        fileName = tempFileName
          .split("")
          .reverse()
          .join("");
        for (let file of this.files) {
          if (file.name === fileName) {
            this.onSelectedFile(file, this.dataProducts.indexOf(ruta));
          }
        }
      }
    },
    onSelectedFile(data, index) {
      this.loader = true;
      this.backdrop = true;
      this.dataProducts[index].file = "";
      let toBase64 = file =>
        new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      async function Main() {
        // let enlace = "";
        let file = data;
        // console.log(file);
        let base64Image = await toBase64(file);
        let name = "photo.jpeg";
        let parseFile = new Parse.File(name, {
          base64: base64Image
        });
        // let savedFile = await parseFile.save();
        // console.log(savedFile);
        // localStorage.setItem(index.toString(), JSON.stringify(savedFile));
        parseFile.save().then(
          savedFile => {
            localStorage.setItem(index.toString(), JSON.stringify(savedFile));
          },
          err => {
            console.error(err);
          }
        );
      }
      Main();
      this.loader = false;
      this.backdrop = false;
    },
    onFileSelected(data, index) {
      this.dataProductInfo[index].file = "";
      let toBase64 = file =>
        new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      async function Main() {
        // let enlace = "";
        let file = data;
        let base64Image = await toBase64(file);
        let name = "photo.jpeg";
        let parseFile = new Parse.File(name, {
          base64: base64Image
        });

        parseFile.save().then(savedFile => {
          localStorage.setItem(index.toString(), JSON.stringify(savedFile));
        });
        // let savedFile = await parseFile.save();
        // console.log(savedFile);
        // localStorage.setItem(index.toString(), JSON.stringify(savedFile));
        // console.log( localStorage.getItem(index.toString()));
      }
      Main();
    },
    //method to read uploaded file with products data (farmacy inventory)
    proccessProductFileNew() {
      alert(
        "Esta vivo... leyendo archivo de productos a actualizar en el inventario..."
      );
    },
    rowToProductJson(row) {
      let product = {},
        keys = Object.keys(row);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (key === "Bar Code" || key === "ItemNum") {
          product.barcode = row[key]; //barcode, upc and upc11 are the same
        } else if (key === "Description" || key === "Report Description") {
          product.Name = row[key];
        } else if (key === "Departamento") {
          product.SubCategory = row[key];
        } else if (key === "Invent") {
          product.quantity = row[key];
        } else if (key === "Price") {
          product.Price = row[key];
        } else {
          //unkown key - column
          product[key] = row[key];
        }
      }
      return product;
    },
    proccessProductFile(event) {
      let selectedFile = event.target.files[0];
      let data = [
        {
          name: "jayanth",
          data: "scd",
          abc: "sdef"
        }
      ];
      XLSX.utils.json_to_sheet(data, "out.xlsx");
      if (selectedFile) {
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(selectedFile);
        fileReader.onload = event => {
          let data = event.target.result;
          let workbook = XLSX.read(data, { type: "binary" });
          workbook.SheetNames.forEach(sheet => {
            let rowObject = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            console.log(
              "result from sheet is " +
                typeof rowObject +
                " length: " +
                rowObject.length
            );
            //this.dataProductInfo = rowObject;
            let maxRows = 900;
            let rows = rowObject.length > maxRows ? maxRows : rowObject.length;
            let results = [];
            for (let i = 0; i < rows; i++) {
              let prod = this.rowToProductJson(rowObject[i]);
              console.log(prod);
              if (
                prod.barcode == null ||
                prod.barcode == "" ||
                prod.Price == null ||
                prod.Price == ""
              ) {
                continue;
              }
              results.push(prod);
            }
            this.dataProductInfo = results;
          });
          console.log("---->Archivo Productos ", this.dataProductInfo.length);
          console.log(this.dataProductInfo);

          //this.updatePharmacyInventory();
        };

        // for(let a = 0; a<=this.dataProductInfo.length; a++){
        //   localStorage.removeItem(a);
        // }
        //alert("aleluya");
      }
    },
    selectStore(store) {
      this.storeSelectedId = store.id;
      this.storeSel = store;
      console.log("Selected Store id: ", this.storeSelectedId);
    },
    getInventoryFromMCS(store) {
      axios
        .post("https://mcsotc.coopharma.app/api/utils/getProductsByPharmacy", {
          pharmacyNpi: store.get("NPI")
        })
        .then(result => {
          console.log(result);
          this.uploadInventoryFromMCS(result.data.result, store);
        })
        .catch(err => {
          console.log(err);
        });
    },
    async uploadInventoryFromMCS(data, store) {
      let prod = Array.from(data);
      console.log(prod);
      if (prod.length > 0) {
        //pharmacy selected
        let inventory = {
            inserts: 0,
            updates: 0,
            total: 0,
            fails: 0,
            categories: []
          },
          itemsCount = 100;
        for (let i = 0; i < prod.length; i = i + itemsCount) {
          let productsList = [],
            end = i + itemsCount <= prod.length ? i + itemsCount : prod.length;
          for (let n = i; n < end; n++) {
            productsList.push(prod[n]);
          }
          try {
            let params = { storeId: store.id, products: productsList };
            console.log(params);
            let result = await Parse.Cloud.run(
              "uploadPharmacyInventoryFromMCS",
              params
            );
            //let result = {inserts: 0, updates: productsList.length, total: productsList.length};
            inventory.inserts += result.inserts;
            inventory.updates += result.updates;
            inventory.total += result.total;
            inventory.fails += result.fails;
            inventory.categories = inventory.categories.concat(
              result.categories
            );
            console.log(
              "--> Batch ( " + i + " - " + (i + itemsCount) + " ) uploaded"
            );
          } catch (ex) {
            console.log(ex);
            //error
            this.backdrop = false;
            this.loader = false;
            console.log(inventory);
            alert("Oops, ha ocurrido un error.");
            return;
          }
        }

        console.log("Finish Uploading inventory!!!!!!! With Results: ");
        console.log(inventory);

        this.backdrop = false;
        this.loader = false;
        alert(
          "Eureka! Inventario actualizado con los productos del archivo. Resultados " +
            inventory.inserts +
            " nuevos, " +
            inventory.updates +
            " actualizados de " +
            inventory.total +
            " en total. Nuevas " +
            inventory.categories.length +
            " categorias"
        );
      } else {
        console.log("Esta vacio");
      }
    },
    async uploadPharmacyInventory() {
      console.log("Seleccionados " + typeof this.seleccionados);
      console.log(this.seleccionados);

      console.log(this.storeSelected);
      console.log("selected store id: ", this.storeSelectedId);

      //let storeId = this.storeSelectedId;
      let storeId =
        this.storeSel != null && this.storeSel.id != null
          ? this.storeSel.id
          : null;

      this.backdrop = true;
      this.loader = true;

      if (this.dataProductInfo == null || this.dataProductInfo.length === 0) {
        alert("No se tiene productos para subir...");
        this.backdrop = false;
        this.loader = false;
        return;
      }

      if (storeId == null || storeId == "") {
        alert("Favor de seleccionar una farmacia a la vez.");
        this.backdrop = false;
        this.loader = false;
        return;
      }

      // if( 1 == 1 ) {
      //   this.backdrop = false;
      //   this.loader = false;
      //   return;
      // }

      //pharmacy selected
      let inventory = {
          inserts: 0,
          updates: 0,
          total: 0,
          fails: 0,
          categories: []
        },
        itemsCount = 100;
      for (let i = 0; i < this.dataProductInfo.length; i = i + itemsCount) {
        let productsList = [],
          end =
            i + itemsCount <= this.dataProductInfo.length
              ? i + itemsCount
              : this.dataProductInfo.length;
        for (let n = i; n < end; n++) {
          productsList.push(this.dataProductInfo[n]);
        }

        try {
          let params = { storeId: storeId, products: productsList };
          let result = await Parse.Cloud.run("uploadPharmacyInventory", params);
          //let result = {inserts: 0, updates: productsList.length, total: productsList.length};
          inventory.inserts += result.inserts;
          inventory.updates += result.updates;
          inventory.total += result.total;
          inventory.fails += result.fails;
          inventory.categories = inventory.categories.concat(result.categories);
          console.log(
            "--> Batch ( " + i + " - " + (i + itemsCount) + " ) uploaded"
          );
        } catch (ex) {
          console.log(ex);
          //error
          this.backdrop = false;
          this.loader = false;
          console.log(inventory);
          alert("Oops, ha ocurrido un error.");
          return;
        }
      }

      console.log("Finish Uploading inventory!!!!!!! With Results: ");
      console.log(inventory);

      this.backdrop = false;
      this.loader = false;
      alert(
        "Eureka! Inventario actualizado con los productos del archivo. Resultados " +
          inventory.inserts +
          " nuevos, " +
          inventory.updates +
          " actualizados de " +
          inventory.total +
          " en total. Nuevas " +
          inventory.categories.length +
          " categorias"
      );
    },
    async proccessFileImages(event) {
      console.log("proccessFileImages...");
      console.log(event);

      //
      if (this.processingImages) {
        return;
      }
      this.processingImages = true;

      let storeId =
        this.storeSel != null && this.storeSel.id != null
          ? this.storeSel.id
          : null;

      if (storeId == null || storeId == "") {
        alert("Selecione farmacia primero.");
        return;
      }

      (this.productImages = []),
        (this.allProducts = []),
        (this.productImageCount = 0);
      this.imageFiles = event.target.files || [];
      let filesName = [];
      // images = [];
      for (let i = 0; i < this.imageFiles.length; i++) {
        let fileName = (this.imageFiles[i].name + "").trim().toLowerCase();
        fileName = fileName
          .replace(".jpg", "")
          .replace(".jpeg", "")
          .replace(".png", "");
        filesName.push(fileName);
      }

      console.log(filesName);

      let toBase64 = file =>
        new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });

      try {
        let allProducts = await Parse.Cloud.run("allProducts", {
          storeId: storeId
        });
        this.allProducts = allProducts;

        //
        for (let i = 0; i < filesName.length; i++) {
          let fname = filesName[i],
            file = this.imageFiles[i],
            // foundProduct = false,
            product = null;
          for (let n = 0; n < allProducts.length; n++) {
            let pname = allProducts[n].get("Name") || "";
            pname = pname.trim().toLowerCase();
            if (pname === fname) {
              //product match image
              // foundProduct = true;
              product = allProducts[n];
              let base64Image = await toBase64(file);
              let name = "photo.jpeg";
              let parseFile = new Parse.File(name, {
                base64: base64Image
              });
              let savedFile = await parseFile.save();
              this.productImages.push({
                image: savedFile,
                productId: product.id
              });
              console.log(
                "#" +
                  (i + 1) +
                  " file name " +
                  file.name +
                  " url " +
                  savedFile.url()
              );
              break;
            }
          }
        }

        //all good
        //this.productImages = images;
        this.allProducts = allProducts;
        this.processingImages = false;

        if (this.productImages.length === 0) {
          alert("No se encontraron productos para las imagenes selecionadas");
        }
      } catch (ex) {
        this.processingImages = false;
        this.productImages = [];
        this.imageFiles = [];
        this.allProducts = [];

        alert("Oops, ha ocurrido un error.");
      }

      // for(let ruta of this.dataProductInfo) {
      //   let fileName = ruta.PATH;
      //   let reverse = fileName.split('').reverse().join('');
      //   let tempFileName = reverse.slice(0, reverse.indexOf('\\'));
      //   fileName = tempFileName.split('').reverse().join('');
      //   for(let file of this.imageFiles) {
      //     if (file.name === fileName) {
      //       this.onFileSelected(file, this.dataProductInfo.indexOf(ruta));
      //     }
      //   }
      // }
    },
    async uploadPharmacyInventoryImages() {
      if (this.processingImages) {
        alert("Tienes que esperar que termine de procesar las fotos.");
        return;
      }

      if (this.productImages.length === 0) {
        alert("No hay imagenes. Tienes que subir por lo menos una imagen.");
        return;
      }

      let storeId =
        this.storeSel != null && this.storeSel.id != null
          ? this.storeSel.id
          : null;

      if (storeId == null || storeId == "") {
        alert("Seleccione farmacia primero.");
        return;
      }

      this.backdrop = true;
      this.loader = true;

      this.productImageCount = 0;

      //
      let inventory = { updates: 0, total: 0, fails: 0 },
        itemsCount = 100;
      for (let i = 0; i < this.productImages.length; i = i + itemsCount) {
        let imagesList = [],
          end =
            i + itemsCount <= this.productImages.length
              ? i + itemsCount
              : this.productImages.length;
        for (let n = i; n < end; n++) {
          imagesList.push(this.productImages[n]);
        }

        try {
          let params = { storeId: storeId, productImages: imagesList };
          let result = await Parse.Cloud.run("updateProductsWithImage", params);
          //let result = {inserts: 0, updates: productsList.length, total: productsList.length};
          inventory.updates += result.updates;
          inventory.total += result.total;
          inventory.fails += result.fails;
          this.productImageCount += result.updates;
          console.log(
            "--> Batch Images ( " + i + " - " + (i + itemsCount) + " ) uploaded"
          );
        } catch (ex) {
          console.log(ex);
          this.backdrop = false;
          this.loader = false;
          console.log(inventory);
          alert("Oops, ha ocurrido un error.");
          return;
        }
      }

      console.log("Finish Uploading Images!!!!!!!");
      console.log(inventory);

      this.backdrop = false;
      this.loader = false;
      alert(
        "Upload Imagenes!! " +
          inventory.updates +
          " productos actualizados de " +
          inventory.total +
          " en total y " +
          inventory.fails +
          " fallidos."
      );
    },
    proccessFileProducts() {
      this.backdrop = true;
      this.loader = true;
      let selectedFile = event.target.files[0];
      let data = [
        {
          name: "jayanth",
          data: "scd",
          abc: "sdef"
        }
      ];
      XLSX.utils.json_to_sheet(data, "out.xlsx");
      if (selectedFile) {
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(selectedFile);
        fileReader.onload = event => {
          let data = event.target.result;
          let workbook = XLSX.read(data, { type: "binary" });
          workbook.SheetNames.forEach(sheet => {
            let rowObject = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            this.dataProducts = rowObject;
          });
        };
        // for(let a = 0; a<=this.dataProducts.length; a++){
        //   localStorage.removeItem(a);
        // }
      }
      this.backdrop = false;
      this.loader = false;
    },
    getCategories() {
      Parse.Cloud.run("getCategories").then(result => {
        for (let data of result) {
          this.categories.push(data);
        }
      });
    },
    gettingStores() {
      Parse.Cloud.run("seeStores").then(result => {
        this.allStore = result;
        this.$store.dispatch("loadedComponentAction");
      });
      let query = new Parse.Query("Store");
      query.equalTo("status", "V");
      query.count().then(storeCount => {
        this.storeTotal = storeCount;
      });
      let query2 = new Parse.Query("_User");
      query2.equalTo("userValidated", true);
      query2.count().then(storeCount => {
        this.validatedTotal = storeCount;
      });
      let query3 = new Parse.Query("_User");
      query3.equalTo("userValidated", true);
      query3.exists("stripeAccountId");
      query3.count().then(stripe => {
        this.totalStripe = stripe;
      });
    },
    changeFarmacia() {
      if (this.esFarmacia) {
        this.esFarmacia = false;
      } else {
        this.esFarmacia = true;
      }
    },
    selectCategory(name) {
      let data = [];
      Parse.Cloud.run("getCategories").then(() => {
        for (let categoria of this.categories) {
          if (categoria.attributes.name === name) {
            data.push(categoria);
          }
        }
      });
      return data;
    },
    categoriesUI() {
      let inputs = document
        .getElementById("categories-list")
        .getElementsByTagName("input");
      let cats = this.info.get("categoryList");
      for (let i = 0; i < inputs.length; i++) {
        let catId = inputs[i].getAttribute("id");
        for (let n = 0; n < cats.length; n++) {
          if (cats[n].id === catId) {
            inputs[i].checked = true; // check input
            break;
          }
        }
      }
    },
    proccessFile(event) {
      let selectedFile;
      selectedFile = event.target.files[0];
      let data = [
        {
          name: "jayanth",
          data: "scd",
          abc: "sdef"
        }
      ];
      XLSX.utils.json_to_sheet(data, "out.xlsx");
      if (selectedFile) {
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(selectedFile);
        fileReader.onload = event => {
          let data = event.target.result;
          let workbook = XLSX.read(data, { type: "binary" });
          workbook.SheetNames.forEach(sheet => {
            let rowObject = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            this.dataInfo = rowObject;
          });
        };
      }
    },
    parseJSONToCSVStr(jsonData) {
      console.log(jsonData);
      let farmacias = XLSX.utils.json_to_sheet(jsonData);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, farmacias, "farmacias"); // sheetAName is name of Worksheet
      XLSX.writeFile(wb, "farmacias.xlsx"); // name of the file is 'book.xlsx'
    },
    exportToCsvFile() {
      // console.log(jsonData);
      // eslint-disable-next-line
      let farmacias = XLSX.utils.json_to_sheet(jsonData);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, farmacias, "farmacias"); // sheetAName is name of Worksheet
      XLSX.writeFile(wb, "farmacias.xlsx"); // name of the file is 'book.xlsx'

      // let csvStr = parseJSONToCSVStr(jsonData);
      // let dataUri = 'data:text/csv;charset=utf-8,'+ csvStr;

      // let exportFileDefaultName = 'data.csv';

      // let linkElement = document.createElement('a');
      // linkElement.setAttribute('href', dataUri);
      // linkElement.setAttribute('download', exportFileDefaultName);
      // linkElement.click();

      // let element = document.getElementById('SellReport');
      // const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

      // /* generate workbook and add the worksheet */
      // const wb: XLSX.WorkBook = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      // /* save to file */
      // XLSX.writeFile(wb, this.fileName);
    },
    proccessReportXLSX() {
      let dataJson = [];
      for (let data of this.allStore) {
        dataJson.push({
          Farmaceutico: data.attributes.employee.attributes.fullName,
          Farmacia: data.attributes.Name,
          Phone: data.attributes.Phone,
          Address: data.attributes.Address,
          Email: data.attributes.employee.attributes.username,
          Validado: data.attributes.employee.attributes.userValidated
            ? "Si"
            : "No"
        });
      }
      this.parseJSONToCSVStr(dataJson);
    },
    sendEmailFarmacia(cuerpoCorreo) {
      let emailData = {};
      if (localStorage.getItem("archivoEmail")) {
        emailData = {
          Host: "smtp.gmail.com",
          Username: "myshop@coopharma.coop",
          Password: "Mysh0p2020",
          To: cuerpoCorreo.Correo,
          From: "myshop@coopharma.coop",
          Subject: "Bienvenido a Coopharma",
          Body:
            "<div style='background-color:#E0E0E0; padding: 20px; width: 80%;'>" +
            "<a href='www.coopharma.coop'><img width='70' src='https://coopharma.app/img/logo-coopharma.e5f23e05.png'></a>" +
            "</div>" +
            "<div style='margin:40px; width:70%; text-align: justify;'>" +
            "<p style = 'font-size: 20px;'>Hola, " +
            cuerpoCorreo.Nombre +
            "</p>" +
            "<p style = 'font-size: 20px;'>Este es un correo de invitacion para el uso de la plataforma de " +
            "<a href='www.coopharma.app' style='color:#0E9390; font-weight:bold;'>www.coopharma.app</a>" +
            ", Para poder validar sus datos en la plataforma con las siguientes credenciales:</p>" +
            "<p style = 'font-size: 20px;'><b style='color:#0E9390;'>Usuario:</b> " +
            cuerpoCorreo.Correo +
            "<br>" +
            "<b style='color:#0E9390;'>Contraseña:</b> " +
            cuerpoCorreo.Password +
            "</p>" +
            "<p>Para poder ingresar acceda a la plataforma usando este enlace <a href='www.coopharma.app'>www.coopharma.app</a>,</p>" +
            "<p style = 'font-size: 20px;'>Una vez haya validado su usuario proceda en la opcion de perfil a cambiar su " +
            "contrasenia,<br>para cualquier duda o inconveniente puede llamar a (787) 520-6018<br>Saludos</p><div>" +
            "<a href='www.coopharma.app'><button style='width:100%;'><p>" +
            "<img width='70' src='https://coopharma.app/img/logo-coopharma.e5f23e05.png'>" +
            "<p style='font-size: 20px; font-weight:bold; color: #ED7422;'>Ir a la Plataforma</p></button></a></div></div>",
          Attachments: [
            {
              name: JSON.parse(localStorage.getItem("archivoEmail")).name,
              path: JSON.parse(localStorage.getItem("archivoEmail")).url
            }
          ]
        };
      } else {
        emailData = {
          Host: "smtp.gmail.com",
          Username: "myshop@coopharma.coop",
          Password: "Mysh0p2020",
          To: cuerpoCorreo.Correo,
          From: "myshop@coopharma.coop",
          Subject: "Bienvenido a Coopharma",
          Body:
            "<div style='background-color:#E0E0E0; padding: 20px; width: 80%;'>" +
            "<a href='www.coopharma.coop'><img width='70' src='https://coopharma.app/img/logo-coopharma.e5f23e05.png'></a>" +
            "</div>" +
            "<div style='margin:40px; width:70%; text-align: justify;'>" +
            "<p style = 'font-size: 20px;'>Hola, " +
            cuerpoCorreo.Nombre +
            "</p>" +
            "<p style = 'font-size: 20px;'>Este es un correo de invitacion para el uso de la plataforma de " +
            "<a href='www.coopharma.app' style='color:#0E9390; font-weight:bold;'>www.coopharma.app</a>" +
            ", Para poder validar sus datos en la plataforma con las siguientes credenciales:</p>" +
            "<p style = 'font-size: 20px;'><b style='color:#0E9390;'>Usuario:</b> " +
            cuerpoCorreo.Correo +
            "<br>" +
            "<b style='color:#0E9390;'>Contraseña:</b> " +
            cuerpoCorreo.Password +
            "</p>" +
            "<p>Para poder ingresar acceda a la plataforma usando este enlace <a href='www.coopharma.app'>www.coopharma.app</a>,</p>" +
            "<p style = 'font-size: 20px;'>Una vez haya validado su usuario proceda en la opcion de perfil a cambiar su " +
            "contrasenia,<br>para cualquier duda o inconveniente puede llamar a (787) 520-6018<br>Saludos</p><div>" +
            "<a href='www.coopharma.app'><button style='width:100%;'><p>" +
            "<img width='70' src='https://coopharma.app/img/logo-coopharma.e5f23e05.png'>" +
            "<p style='font-size: 20px; font-weight:bold; color: #ED7422;'>Ir a la Plataforma</p></button></a></div></div>"
        };
      }
      // eslint-disable-next-line
      Email.send(emailData).then(() => {
        if (localStorage.getItem("archivoEmail")) {
          localStorage.removeItem("archivoEmail");
        }
      });
    },
    signIn(data) {
      let archivoData = {};
      this.isProcessing = true;
      const schedule = [
        {
          day: "Sunday",
          start: "",
          end: ""
        },
        {
          day: "Monday",
          start: "8:00AM",
          end: "9:00PM"
        },
        {
          day: "Tuesday",
          start: "8:00AM",
          end: "9:00PM"
        },
        {
          day: "Wednesday",
          start: "8:00AM",
          end: "9:00PM"
        },
        {
          day: "Thursday",
          start: "8:00AM",
          end: "9:00PM"
        },
        {
          day: "Friday",
          start: "8:00AM",
          end: "11:00PM"
        },
        {
          day: "Saturday",
          start: "8:00AM",
          end: "11:00PM"
        }
      ];
      const user = new Parse.User();
      const SubCategory = Parse.Object.extend("SubCategory");
      const subCategory = new SubCategory();
      let password = Math.random()
        .toString(36)
        .substring(2);
      // eslint-disable-next-line
      let telefono = data.Telefono.replace(/[-+()\ /s]/g, "");
      let nombre = data.Nombre.substr(0, data.Nombre.indexOf(" "));
      let apellido = data.Nombre.substr(
        data.Nombre.indexOf(" "),
        data.Nombre.length
      );
      user.set("email", data["Correo Electrónico "]);
      user.set("password", password);
      user.set("username", data["Correo Electrónico "]);
      user.set("firstName", nombre);
      user.set("lastName", apellido);
      user.set("fullName", data.Nombre);
      user.set("superAdmin", false);
      user.set("isAdmin", true);
      user.set("isApproved", true);
      user.set("userValidated", false);
      user
        .signUp()
        .then(() => {
          // const query = new Parse.Query("Category");
          const Store = Parse.Object.extend("Store");
          const store = new Store();
          store.set("employee", user);
          store.set("Description", "");
          store.set("Name", data.Farmacia);
          store.set("Phone", parseInt(telefono.substr(0, 10)));
          store.set("Schedule", schedule);
          store.set("categoryList", this.selectCategory(data.Pueblo));
          store.set("Address", data["Dirección fisica"]);
          store.set("isApproved", true);
          store.set("activate", true);
          store.set("status", "V");
          store.set("logkey", btoa(password));
          this.sendEmailFarmacia({
            Nombre: data.Nombre,
            Password: password,
            Correo: data["Correo Electrónico "]
          });
          store
            .save()
            .then(() => {
              subCategory.set("employeeId", user);
              subCategory.set("storeId", store);
              subCategory.set("subCategory", this.newSubCategory);
              subCategory.save();
              if (this.dataProductInfo) {
                for (let dataproduct of this.dataProductInfo) {
                  if (
                    localStorage.getItem(
                      this.dataProductInfo.indexOf(dataproduct)
                    )
                  ) {
                    archivoData = JSON.parse(
                      localStorage.getItem(
                        this.dataProductInfo.indexOf(dataproduct)
                      )
                    );
                    // console.log(archivoData);
                    const Product = Parse.Object.extend("Products");
                    const product = new Product();
                    product.set("Price", "0");
                    product.set(
                      "Description",
                      "Producto precargado de Farmacia"
                    );
                    product.set("Name", dataproduct.ProductName);
                    product.set("employee", user);
                    product.set("storeId", store);
                    product.set("picture", archivoData);
                    product.set("SubCategory", "OTC");
                    product.set("outOfStock", true);
                    product.set("OriginalPrice", "0");
                    product.set("applyIvu", false);
                    product.set("ivuPercent", 0);
                    product.set("quantity", "0");
                    product.set("shopper", 0);
                    product.set("esShopper", false);
                    product.set("offer", 0);
                    product.set("IsOffer", false);
                    product.set("dist", dataproduct.Dist);
                    product.set("CIN_GCN", parseInt(dataproduct.CIN_GCN));
                    product.set("majorUnit", dataproduct.MajorUnit);
                    product.set("minorUnit", dataproduct.MinorUnit);
                    product.set("ndc", parseInt(dataproduct.NDC));
                    product.set("packSize", dataproduct.PackSize);
                    product.set(
                      "productNumberFamily",
                      dataproduct.ProductNumberFamily
                    );
                    product.set("supplierName", dataproduct.SupplierName);
                    product.set("upc", dataproduct.UPC);
                    product.set("upc11", dataproduct["11DigitUPC"]);
                    product.set("barcode", dataproduct.UPC);
                    product.save().catch(error => {
                      console.log(error);
                    });
                  } else {
                    const Product = Parse.Object.extend("Products");
                    const product = new Product();
                    product.set("Price", "");
                    product.set(
                      "Description",
                      "Producto precargado de Farmacia"
                    );
                    product.set("Name", dataproduct.ProductName);
                    product.set("employee", user);
                    product.set("storeId", store);
                    product.set("SubCategory", "OTC");
                    product.set("outOfStock", true);
                    product.set("OriginalPrice", "0");
                    product.set("applyIvu", false);
                    product.set("ivuPercent", 0);
                    product.set("quantity", "0");
                    product.set("shopper", 0);
                    product.set("esShopper", false);
                    product.set("offer", 0);
                    product.set("IsOffer", false);
                    product.set("dist", dataproduct.Dist);
                    product.set("upc11", dataproduct["11DigitUPC"]);
                    product.set("CIN_GCN", parseInt(dataproduct.CIN_GCN));
                    product.set("majorUnit", dataproduct.MajorUnit);
                    product.set("minorUnit", dataproduct.MinorUnit);
                    product.set("ndc", parseInt(dataproduct.NDC));
                    product.set("packSize", dataproduct.PackSize);
                    product.set(
                      "productNumberFamily",
                      dataproduct.ProductNumberFamily
                    );
                    product.set("supplierName", dataproduct.SupplierName);
                    product.set("upc", dataproduct.UPC);
                    product.set("barcode", dataproduct.UPC);
                    product.save();
                  }
                }
              }
            })
            .catch(cancel => {
              console.log(cancel);
              this.farmaciasCreadas.push({
                correo: data.Farmacia,
                status: false
              });
              // this.$swal({
              //   title: "Error",
              //   text: data.Farmacia+" no pudo ser creada",
              //   type: "error",
              // });
              alert(data.Farmacia + " no pudo ser creada");
            });
        })
        .catch(cancel => {
          console.log(cancel);
          this.usuariosCreados.push({
            correo: data["Correo Electrónico "],
            status: false
          });
          // this.$swal({
          //   title: "Error",
          //   text: "Usuario "+data["Correo Electrónico "]+" no pudo ser creada",
          //   type: "error",
          // });
          alert(
            "Usuario " + data["Correo Electrónico "] + " no pudo ser creada"
          );
        });
      // if (true) {
      //   // this.$swal({
      //   //   title: "Creados",
      //   //   text: "Usuarios y Farmacias Creados, valide por favor",
      //   //   type: "success",
      //   // });
      //   alert("Usuarios y Farmacias Creados, valide por favor");
      // } else {
      //   // this.$swal({
      //   //   title: "Error",
      //   //   text: "Algunas farmacias no pudieron ser creadas o sus usuarios, valide por favor ",
      //   //   type: "error",
      //   // });
      //   alert(
      //     "Algunas farmacias no pudieron ser creadas o sus usuarios, valide por favor "
      //   );
      // }
    },
    proccessMultipleFarmacies() {
      this.loader = true;
      this.backdrop = true;
      let totalProducts = this.dataProductInfo.length;
      let totalFotos = 0;
      for (let a = 0; a <= totalProducts; a++) {
        if (localStorage.getItem(a.toString())) {
          totalFotos++;
        }
      }
      if (
        confirm(
          "Tiene " +
            totalProducts +
            " productos y " +
            totalFotos +
            " fotos a subir, deseas proceder la subida de productos con estas fotos?"
        )
      ) {
        if (this.dataInfo) {
          for (let data of this.dataInfo) {
            this.signIn(data);
          }
        }
        this.loader = false;
        this.backdrop = false;
      } else {
        this.loader = false;
        this.backdrop = false;
      }
    },
    increment() {
      this.newvalue = this.counter++;
      this.finish = this.newvalue;
      return this.finish;
    },
    viewInfo(data) {
      this.$router.push("/Farmacies/" + data);
    },
    deleteStore(store) {
      Parse.Cloud.run("setHideStore", {
        storeId: store.id
      }).then(result => {
        console.log(result);
        this.gettingStores();
      });
    },
    savePrioridad() {
      if (this.prioridad !== null) {
        this.store.set("order", parseInt(this.prioridad));
        this.store.save().then(result => {
          console.log(result);
          alert("Nuevo orden de prioridad estableciod.");
          window.document.getElementById("close-btn").click();
          return;
        });
      } else {
        alert("¡Debe ingresar un valor de prioridad!");
        return;
      }
    },
    setPrioridad(store) {
      this.store = store;
      if (this.store.get("order") !== null) {
        this.prioridad = this.store.get("order");
        return;
      }
    },
    redirectToRequest() {
      this.$router.push({
        name: "acceptStore"
      });
    },
    redirectToAdminStore() {
      this.$router.push({
        name: "adminStores"
      });
    },
    turnOff(store) {
      store.set("activate", false);
      store.save().then(() => {
        this.gettingStores();
      });
    },
    onActivate(state) {
      this.store.set("activate", state);
      this.store.save().then(() => {
        this.gettingStores();
      });
    },
    onShopperOff(store) {
      store.set("esShopper", false);
      store.save().then(() => {
        this.gettingStores();
      });
    },
    onShopperOn(store) {
      store.set("esShopper", true);
      store.save().then(() => {
        this.gettingStores();
      });
    },
    turnOn(store) {
      store.set("activate", true);
      store.save().then(() => {
        this.gettingStores();
      });
    },
    openPorcentage(aStore) {
      this.store = aStore;
      let percen = "";
      if (this.store.get("percent") !== null) {
        percen = this.store.get("percent") * 100 + "";
      }
      this.percent = percen;
    },
    savePercent() {
      if (this.store !== null && this.percent !== "") {
        if (this.processing) {
          return;
        }
        this.processing = true;
        const value = parseFloat(this.percent) / 100;
        if (isNaN(value)) {
          this.processing = false;
          return;
        }
        this.store.set("percent", value);
        this.store.save().then(
          success => {
            console.log(success);
            this.processing = false;
            window.document.getElementById("close-btn").click();
          },
          error => {
            console.log(error);
            this.processing = false;
          }
        );
      }
    },
    cleanData() {
      this.Name = "";
      this.Last = "";
      this.Phone = "";
      this.Email = "";
      this.Farmacy = "";
      this.Address = "";
    },
    createFarmacia() {
      let archivoData = {};
      this.isProcessing = true;
      const SubCategory = Parse.Object.extend("SubCategory");
      const subCategory = new SubCategory();
      this.loader = true;
      this.backdrop = true;
      let totalProducts = this.dataProductInfo.length;
      let totalFotos = 0;
      for (let a = 0; a <= totalProducts; a++) {
        if (localStorage.getItem(a.toString())) {
          totalFotos++;
        }
      }
      const schedule = [
        {
          day: "Sunday",
          start: "",
          end: ""
        },
        {
          day: "Monday",
          start: "8:00AM",
          end: "9:00PM"
        },
        {
          day: "Tuesday",
          start: "8:00AM",
          end: "9:00PM"
        },
        {
          day: "Wednesday",
          start: "8:00AM",
          end: "9:00PM"
        },
        {
          day: "Thursday",
          start: "8:00AM",
          end: "9:00PM"
        },
        {
          day: "Friday",
          start: "8:00AM",
          end: "11:00PM"
        },
        {
          day: "Saturday",
          start: "8:00AM",
          end: "11:00PM"
        }
      ];

      if (this.Name === "") {
        this.esName = true;
      } else {
        this.esName = false;
      }
      if (this.Last === "") {
        this.esLast = true;
      } else {
        this.esLast = false;
      }
      if (this.Phone === "") {
        this.esPhone = true;
      } else {
        this.esPhone = false;
      }
      if (this.Email === "") {
        this.esEmail = true;
      } else {
        this.esEmail = false;
      }
      if (this.Farmacy === "") {
        this.esFarmacy = true;
      } else {
        this.esFarmacy = false;
      }
      if (this.Address === "") {
        this.esAdress = true;
      } else {
        this.esAdress = false;
      }

      if (
        !this.esName &&
        !this.esLast &&
        !this.esPhone &&
        !this.esEmail &&
        !this.esFarmacy &&
        !this.esAdress
      ) {
        if (
          confirm(
            "Tiene " +
              totalProducts +
              " productos y " +
              totalFotos +
              " fotos a subir, deseas proceder la subida de productos con estas fotos?"
          )
        ) {
          const user = new Parse.User();
          let password = Math.random()
            .toString(36)
            .substring(2);
          user.set("email", this.Email);
          user.set("password", password);
          user.set("username", this.Email);
          user.set("firstName", this.Name);
          user.set("lastName", this.Last);
          user.set("fullName", this.Name + " " + this.Last);
          user.set("superAdmin", false);
          user.set("isAdmin", true);
          this.store.set("isApproved", true);
          user.set("userValidated", false);
          user.signUp().then(() => {
            // const query = new Parse.Query("Category");
            const Store = Parse.Object.extend("Store");
            const store = new Store();
            store.set("employee", user);
            store.set("Description", this.Descripcion);
            store.set("Name", this.Farmacy);
            store.set("Phone", parseInt(this.Phone));
            store.set("Schedule", schedule);
            store.set("categoryList", this.categoryList);
            store.set("Address", this.Address);
            store.set("isApproved", true);
            store.set("activate", true);
            store.set("status", "V");
            store.set("logkey", btoa(password));
            this.sendEmailFarmacia({
              Nombre: this.Name + " " + this.Last,
              Password: password,
              Correo: this.Email
            });
            store.save().then(farmacia => {
              subCategory.set("employeeId", user);
              subCategory.set("storeId", farmacia);
              subCategory.set("subCategory", this.newSubCategory);
              subCategory.save();
              if (this.dataProducts) {
                for (let dataproduct of this.dataProducts) {
                  if (
                    localStorage.getItem(this.dataProducts.indexOf(dataproduct))
                  ) {
                    archivoData = JSON.parse(
                      localStorage.getItem(
                        this.dataProducts.indexOf(dataproduct)
                      )
                    );
                    // console.log(archivoData);
                    const Product = Parse.Object.extend("Products");
                    const product = new Product();
                    product.set("Price", "0");
                    product.set(
                      "Description",
                      "Producto precargado de Farmacia"
                    );
                    product.set("Name", dataproduct.ProductName);
                    product.set("employee", user);
                    product.set("storeId", store);
                    product.set("picture", archivoData);
                    product.set("SubCategory", "OTC");
                    product.set("outOfStock", true);
                    product.set("OriginalPrice", "0");
                    product.set("applyIvu", false);
                    product.set("ivuPercent", 0);
                    product.set("quantity", "0");
                    product.set("shopper", 0);
                    product.set("esShopper", false);
                    product.set("offer", 0);
                    product.set("IsOffer", false);
                    product.set("dist", dataproduct.Dist);
                    product.set("upc11", dataproduct["11DigitUPC"]);
                    product.set("CIN_GCN", parseInt(dataproduct.CIN_GCN));
                    product.set("majorUnit", dataproduct.MajorUnit);
                    product.set("minorUnit", dataproduct.MinorUnit);
                    product.set("ndc", parseInt(dataproduct.NDC));
                    product.set("packSize", dataproduct.PackSize);
                    product.set(
                      "productNumberFamily",
                      dataproduct.ProductNumberFamily
                    );
                    product.set("supplierName", dataproduct.SupplierName);
                    product.set("upc", dataproduct.UPC);
                    product.set("barcode", dataproduct.UPC);
                    product.save();
                  } else {
                    const Product = Parse.Object.extend("Products");
                    const product = new Product();
                    product.set("Price", "");
                    product.set(
                      "Description",
                      "Producto precargado de Farmacia"
                    );
                    product.set("Name", dataproduct.ProductName);
                    product.set("employee", user);
                    product.set("storeId", store);
                    product.set("SubCategory", "OTC");
                    product.set("outOfStock", true);
                    product.set("OriginalPrice", "0");
                    product.set("applyIvu", false);
                    product.set("ivuPercent", 0);
                    product.set("quantity", "0");
                    product.set("shopper", 0);
                    product.set("esShopper", false);
                    product.set("offer", 0);
                    product.set("IsOffer", false);
                    product.set("dist", dataproduct.Dist);
                    product.set("upc11", dataproduct["11DigitUPC"]);
                    product.set("CIN_GCN", parseInt(dataproduct.CIN_GCN));
                    product.set("majorUnit", dataproduct.MajorUnit);
                    product.set("minorUnit", dataproduct.MinorUnit);
                    product.set("ndc", parseInt(dataproduct.NDC));
                    product.set("packSize", dataproduct.PackSize);
                    product.set(
                      "productNumberFamily",
                      dataproduct.ProductNumberFamily
                    );
                    product.set("supplierName", dataproduct.SupplierName);
                    product.set("upc", dataproduct.UPC);
                    product.set("barcode", dataproduct.UPC);
                    product.save();
                  }
                }
              }
              alert(
                "Usuario " +
                  this.Email +
                  " y " +
                  this.Farmacy +
                  " Han sido creada"
              );
              this.cleanData();
            });
          });
          this.loader = false;
          this.backdrop = false;
        } else {
          this.loader = false;
          this.backdrop = false;
        }
      }
    }
  }
};
</script>
<style scoped>
.fa-bars {
  color: rgb(184, 184, 184);
  font-size: 25px;
}

.icon3 {
  color: #029693;
  margin-right: 10px;
}

.nav-link2 {
  color: white !important;
  font-weight: 300;
  font-size: 14px;
  transition: 0.5s;
  text-decoration: none;
  border-color: rgb(44, 44, 44);
  background-color: rgb(44, 44, 44);
}
.required {
  font-size: 15px;
  padding: 5px;
  background-color: #ffcccc;
}

.nav-link3 {
  color: white !important;
  font-weight: 300;
  font-size: 14px;
  transition: 0.5s;
  text-decoration: none;
  border-color: #0e9390;
  background-color: #0e9390;
}

.nav-position {
  padding-top: 30px;
}

.second-nav {
  background: #0e9390;
  height: 50px;
  padding-top: 13px;
}

div.scroll {
  overflow-x: scroll;
  overflow-y: scroll;
  width: 100%;
  height: 500px;
}

.third-nav {
  background: rgb(44, 44, 44);
  height: 50px;
  padding-top: 13px;
}

.second-nav:hover {
  background: #0a6b69;
  height: 50px;
  padding-top: 13px;
  transition: 0.5s;
}

.third-nav:hover {
  background: rgba(44, 44, 44, 0.692);
  height: 50px;
  padding-top: 13px;
  transition: 0.5s;
}

.header {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
}

.content {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  text-align: center;
  padding-top: 10px;
}

.delete-btn {
  background: rgba(212, 42, 42, 0.836);
  border-radius: 5px;
  height: 35px;
  border: none;
  color: white;
  font-weight: 600;
  width: 100px;
}

.inactive {
  background-color: #949191;
  border-radius: 5px;
  height: 35px;
  border: none;
  color: white;
  font-weight: 600;
  width: 100px;
}
.Active {
  background-color: green;
  border-radius: 5px;
  height: 35px;
  border: none;
  color: white;
  font-weight: 600;
  width: 100px;
}

.see-btn {
  margin: 5px;
  border-radius: 5px;
  background: #a01313;
  height: 35px;
  border: none;
  color: white;
  font-weight: 800;
  width: 100px;
}

.see-btn2 {
  margin: 5px;
  border-radius: 5px;
  background: #0e9390;
  height: 35px;
  border: none;
  color: white;
  font-weight: 800;
  width: 100px;
}
</style>
