<template>
  <div style="background: #F8F9FA; height: 100vh; overflow: scroll;">
    <div
      class="container"
      style="margin-top: 30px; background: white; border: 1px solid rgb(192, 192, 192);"
    >
      <p
        v-if="stores === null || stores === ''"
        style="font-size:14px; text-aling:center;"
      >
        No hay solicitudes en estos momentos.
      </p>
      <table
        v-if="stores != null || stores != ''"
        class="table table-hover table-striped"
      >
        <thead>
          <tr>
            <th class="header" scope="col">Nombre de Farmacia</th>
            <th class="header" scope="col">Actions</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(store, index) in stores" :key="index">
            <td class="content">
              <p style="font-size: 16px; text-align: center;">
                {{ store.get("Name") }}
              </p>
              <p style="text-align: center;">
                {{ store.get("employee").get("username") }}
              </p>
            </td>
            <td class="content">
              <button @click="viewInfo(store)" type="button" class="see-btn">
                Ver
              </button>
              <button @click="approved(store)" type="button" class="delete-btn">
                Aprobar
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import Parse from "parse";
export default {
  name: "FarmacyRequestList",
  data() {
    return {
      Name: "",
      Address: "",
      esAdress: false,
      Descripcion: "",
      stores: null,
      dataInfo: null,
      dataProductInfo: null,
      dataProducts: null,
      isProcessing: false,
      categories: [],
      categoryList: [],
      loader: false,
      backdrop: false,
      farmaciasCreadas: [],
      usuariosCreados: [],
      manejoCreados: [],
      esFarmacia: true,
      files: [],
      filtroFarmacia: ""
    };
  },
  props: ["filter"],
  watch: {
    filter() {
      this.stores = [];
      Parse.Cloud.run("getNotAppovedStore").then(result => {
        for (let data of result) {
          if (
            data.attributes.Name.toLowerCase().includes(
              this.filter.toLowerCase()
            )
          ) {
            this.stores.push(data);
          }
        }
      });
    }
  },
  mounted() {
    console.log(this.$props.filter);
    this.getRequestInfo();
  },
  methods: {
    changeFarmacia() {
      if (this.esFarmacia) {
        this.esFarmacia = false;
      } else {
        this.esFarmacia = true;
      }
    },
    viewInfo(store) {
      this.$router.push("/Farmacies/" + store.id);
    },
    getRequestInfo() {
      Parse.Cloud.run("getNotAppovedStore").then(result => {
        this.stores = result;
        this.$store.dispatch("loadedComponentAction");
      });
    },
    approved(data) {
      data.set("isApproved", true);
      data.set("status", "V");
      data.save().then(() => {
        this.getRequestInfo();
      });
    },
    sendEmailFarmacia(cuerpoCorreo) {
      // eslint-disable-next-line
      Email.send({
        Host: "smtp.gmail.com",
        Username: "serviciotecnico@coopharma.coop",
        Password: "Mysh0p2020",
        To: cuerpoCorreo.Correo,
        From: "serviciotecnico@coopharma.coop",
        Subject: "Bienvenido a Coopharma",
        Body:
          "<div style='background-color:#E0E0E0; padding: 20px; width: 80%;'>" +
          "<a href='www.coopharma.coop'><img src='https://coopharma.app/img/logo-coopharma.e5f23e05.png'></a>" +
          "</div>" +
          "<div style='margin:40px; width:70%; text-align: justify;'>" +
          "<p style = 'font-size: 20px;'>Hola, " +
          cuerpoCorreo.Nombre +
          "</p>" +
          "<p style = 'font-size: 20px;'>Este es un correo de invitacion para el uso de la plataforma de " +
          "<a href='www.coopharma.app' style='color:#0E9390; font-weight:bold;'>www.coopharma.app</a>" +
          ", Para poder validar sus datos en la plataforma con las siguientes credenciales en <a href = 'www.coopharma.app'>www.coopharma.app</a>:</p>" +
          "<p style = 'font-size: 20px;'><b style='color:#0E9390;'>Usuario:</b> " +
          cuerpoCorreo.Correo +
          "<br>" +
          "<b style='color:#0E9390;'>Contraseña:</b> " +
          cuerpoCorreo.Password +
          "</p>" +
          "<p>Para poder ingresar acceda a la plataforma usando este enlace <a href='www.coopharma.app'>www.coopharma.app</a>,</p>" +
          "<p style = 'font-size: 20px;'>Una vez haya validado su usuario proceda en la opcion de perfil a cambiar su " +
          "contrasenia,<br>para cualquier duda o inconveniente puede llamar a (787) 520-6018<br>Saludos</p><div>" +
          "<a href='www.coopharma.app'><button style='width:100%;'><p>" +
          "<img src='https://coopharma.app/img/logo-coopharma.e5f23e05.png' style='width:150px;'>" +
          "<p style='font-size: 20px; font-weight:bold; color: #ED7422;'>Ir a la Plataforma</p></button></a></div></div>"
      });
    }
  }
};
</script>
<style scoped>
.header {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
}

.content {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  text-align: center;
}

.delete-btn {
  background: #0e9390;
  height: 35px;
  border: none;
  color: white;
  font-weight: 400;
  width: 100px;
}
.see-btn {
  background: rgb(44, 44, 44);
  height: 35px;
  border: none;
  color: white;
  font-weight: 400;
  width: 100px;
}
</style>
